<template>
  <b-row>
    <b-col md="6">
      <b-card-code no-body title="Create new Learn and Develop toolkit">
        <b-card-body>
          <validation-observer ref="newLearnandDevelopToolkit">
            <div class="mt-1">
              <label>Title</label>
              <validation-provider
                #default="{ errors }"
                name="title"
                rules="required"
              >
                <b-form-input v-model="title" type="text" placeholder="Title" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>

            <div class="mt-1">
              <label>Category</label>
              <validation-provider
                #default="{ errors }"
                name="category"
                rules="required"
              >
                <b-form-select
                  v-model="category"
                  :options="categoryOptions"
                  class="w-100"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>

            <div class="mt-1">
              <label>Description</label>
              <validation-provider
                #default="{ errors }"
                name="description"
                :rules="{
                  required: true,
                }"
              >
                <b-form-textarea
                  v-model="description"
                  placeholder="Description"
                  rows="3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>

            <div class="mt-1">
              <label>Image</label>
              <validation-provider
                v-slot="{ validate, errors }"
                ref="provider"
                rules="required|size:4096"
                name="image file"
              >
                <b-form-file
                  accept=".jpg, .png, .jpeg"
                  placeholder="Choose an image or drop it here..."
                  drop-placeholder="Drop file here..."
                  @change="handleFileChange($event) || validate($event)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>

            <div class="mt-1">
              <label class="mb-1">Select professional</label>
              <v-select 
                v-model="selectedProfessional"                
                label="full_name"
                :options="professionals"          
              >
                <template v-slot:option="option">
                    {{ option.first_name + " " + option.last_name }}
                </template>
              </v-select>
              <!--
              <b-form-select
                @change="setProfessionalInfo()"
                v-model="selectedProfessional"
              > 
                <b-form-select-option v-for="professional in professionals" :value="professional" :key="professional.id" >
                  {{professional.first_name + " " + professional.last_name}}
                </b-form-select-option>
              </b-form-select>
              -->
            </div>

            <div class="mt-1">
              <label>Created by</label>
                <b-form-input v-model="author" maxlength="50" type="text" placeholder="Created by" />
            </div>

            <div class="mt-1">
              <label>About the Author</label>
              <b-form-textarea
                  v-model="about_author"
                  placeholder="About the Author"
                  rows="3"
              />
            </div>

            <b-form-group label="Add to specific questionnaires or categories" class="my-2">
              <b-form-radio-group
                id="radio-group-1"
                v-model="selectedRadioButtonValue"
                :options="options"
                name="radio-options"
              ></b-form-radio-group>
            </b-form-group>

            <div class="mt-1" v-if="selectedRadioButtonValue == 'questionnaires'">
              <label class="mb-1">Recommended questionnaires</label>
              <v-select
                v-model="selectedQuestionnaire"
                multiple
                label="title"
                :options="questionnaires"
              />
            </div>

            <div class="mt-1" v-if="selectedRadioButtonValue == 'categories'">
              <label class="mb-1">Categories</label>
              <v-select
                v-model="selectedCategory"
                multiple
                label="name"
                :options="categories"
              />
            </div>

            <div class="mt-2">
              <div v-if="showUploadSpinner" class="pr-2">
                {{ uploadCompletedPercentage }}% completed
              </div>
              <b-button
                variant="primary"
                :disabled="showUploadSpinner"
                @click="createToolkit"
              >
                <b-spinner v-if="showUploadSpinner" small class="mr-1" />
                <span v-if="showUploadSpinner">Creating...</span>
                <span v-else>Create</span>
              </b-button>
            </div>
          </validation-observer>
        </b-card-body>
      </b-card-code>
    </b-col>
  </b-row>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import EventBus from "../../main.js"

import {
  BCardBody,
  BCardText,
  BFormFile,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BFormSelectOption,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BFormGroup,
  BFormRadioGroup
} from "bootstrap-vue";
import vSelect from 'vue-select'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { size, required } from "@validations";
export default {
  components: {
    BCardCode,
    BCardBody,
    BCardText,
    BFormFile,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormSelectOption,
    BRow,
    BCol,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    vSelect,
    BFormGroup,
    BFormRadioGroup
  },
  data() {
    return {
      selectedRadioButtonValue: 'categories',
      options: [        
        { text: 'Questionnaire Categories', value: 'categories' },
        { text: 'Specific questionnaires', value: 'questionnaires' }
      ],
      title: "",
      category: "",
      categoryOptions: [],
      description: "",
      image: [],
      author: "",
      about_author: "",
      required,
      size,
      showUploadSpinner: false,
      uploadCompletedPercentage: "",
      selectedCategory: "",
      // Recommended questionnaires
      selectedQuestionnaire: [],
      questionnaires: [],
      categories: [],

      // Professional profiles
      selectedProfessional: null,
      professionals: [],
    };
  },
  methods: {
    async handleFileChange(e) {
      if (
        e.target.files[0].type !== "image/jpeg" &&
        e.target.files[0].type !== "image/png"
      ) {
        e.target.value = [];
        this.$refs.provider.applyResult({
          errors: ["Image must be in .jpg, .jpeg or .png format"], // array of string errors
          valid: false, // boolean state
          failedRules: {}, // should be empty since this is a manual error.
        });
      } else {
        const { valid } = await this.$refs.provider.validate(e);
        if (valid) {
          this.image = e.target.files[0];
        }
      }
    },
    createToolkit() {
      this.$refs.newLearnandDevelopToolkit.validate().then((success) => {
        if (success) {

          let questionnaireIds = [];
          for (let i = 0; i < this.selectedQuestionnaire.length; i++) {
            questionnaireIds.push(this.selectedQuestionnaire[i].id);            
          }

          let categorieIds = [];
          for (let i = 0; i < this.selectedCategory.length; i++) {
            categorieIds.push(this.selectedCategory[i].id);            
          }

          let formData = new FormData();
          formData.append("title", this.title);
          formData.append("category", this.category);
          formData.append("description", this.description);
          formData.append("image", this.image);
          formData.append("author", this.author);
          formData.append("about_author", this.about_author);
          
          if(this.selectedRadioButtonValue == 'questionnaires') {
            formData.append("recommended_questionnaires", JSON.stringify(questionnaireIds));
          } else if(this.selectedRadioButtonValue == 'categories') {
            formData.append("recommended_categories", JSON.stringify(categorieIds));
          }

          if(this.selectedProfessional != null) {
            formData.append("professional_id", this.selectedProfessional.id);
          }

          // show spinner
          this.showUploadSpinner = true;
          
          //Request to create toolkit
          this.$http
            .post("/api/auth/admin/toolkits", formData, {
              onUploadProgress: (uploadEvent) => {
                this.uploadCompletedPercentage = Math.round(
                  (uploadEvent.loaded / uploadEvent.total) * 100
                );
              },
            })
            .then((res) => {
              if (res != undefined) {
                if (res.status == 201) {
                  // Redirect to new toolkit edit page
                  this.$router.push({
                    name: "learn-and-develop-edit",
                    params: { id: res.data.id },
                  });
                }
              }

              // hide spinner
              this.showUploadSpinner = false;
            })
            .catch((err) => {
              console.log(err);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Something went wrong, please login again",
                  icon: "AlertCircleIcon",
                  variant: "danger",
                },
              });

              // hide spinner
              this.showUploadSpinner = false;
            });
        }
      });
    },
    getAllToolkitCategories() {
      this.$http.get("/api/auth/toolkit-categories")
        .then((res) => {
          for(let i=0; i<res.data.length; i++) {
             this.category = res.data[0].id
            // Push categires direct to categoryOptions
            this.categoryOptions.push({ value: res.data[i].id, text: res.data[i].name })
          }
        })
        .catch((err) => {
            console.log(err);
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Something went wrong. Please login again",
                    icon: "AlertCircleIcon",
                    variant: "danger",
                },
            });
        })
    },
    getAllQuestionnaires() {
      this.$http.get("/api/auth/admin/questionnaire")
        .then((res) => {
          for(let i=0; i<res.data.length; i++) {
            // Push questionnaires direct to questionnaires
            this.questionnaires.push({ title: res.data[i].title, id: res.data[i].id })
          }
        })
        .catch((err) => {
            console.log(err);
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Something went wrong. Please login again",
                    icon: "AlertCircleIcon",
                    variant: "danger",
                },
            });
        })
    },
    getProfessionalProfiles() {
      // Get professionals
      this.$http.get("/api/auth/admin/professionals?listAll=true")
        .then((res) => {
          if (res != undefined) {
            res.data.forEach(element => {
              element.full_name = element.first_name + " " + element.last_name
              this.professionals.push(element)
            })
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Something went wrong, please login again",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
    },
    getQuestionnaireCategories() {
      this.$http.get("/api/questionnaire-category")
      .then((res) => {
        if (res != undefined) {
          this.categories = res.data
        }
      })
      .catch((err) => {
        console.log(err);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Something went wrong, please login again",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
      });
    }
  },
  mounted() {
    this.getAllToolkitCategories()
    this.getAllQuestionnaires()
    this.getProfessionalProfiles()
    this.getQuestionnaireCategories();
  },
  watch: {
    selectedProfessional: function (professional) {
      this.author = professional.first_name + " " + this.selectedProfessional.last_name
      this.about_author = professional.profile_description
    }
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
.vs__selected {
  background-color: var(--mhc-dark);
}

.vs__dropdown-option--selected {
  background-color: var(--mhc-dark);
  color: #fff;
}

.vs__dropdown-option.vs__dropdown-option--selected.vs__dropdown-option--highlight {
  background-color: var(--mhc-light)!important;
  color: #fff;
}

.vs__dropdown-option--highlight {
  background-color: #d0e2e440!important;
  color: #6e6b7b!important;
}

</style>